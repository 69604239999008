<template>
  <div v-if="currentUser && (currentUser.type == 'TeleConsultant' || currentUser.type == 'NRP' || currentUser.type == 'CancelledOrder' || currentUser.type =='ToRemind')">
    <div
      v-if="showButtonStart == true"
      class="lex-1 mb-5 mt-1 bg-gray-100 px-4 pt-3 items-center"
    >
      <div class="bg-white w-full rounded-lg shadow">
        <div class="flex justify-center">
          <img
            v-if="showButtonStart == true"
            src="../../assets/Confirmation.svg"
            alt="Image"
            class="img-fluid"
            width="40%"
          />
        </div>
        <div class="flex justify-center">
          <div
            :class="
              showButtonStart == true
                ? 'flex justify-center w-1/3 mt-1 pb-20 pt-1 space-x-2'
                : 'flex justify-center w-1/3 mt-1  pt-1 space-x-2'
            "
          >
            <div class="flex flex-col">
              <div class="mt-1 flex justify-center">
                <button  
          
                  class="relative loidingconfirm flex bg-green-500 rounded-lg font-bold text-white text-center px-10 py-3 transition duration-300 ease-in-out hover:bg-green-600 mr-6 focus:outline-none" :disabled="loading" v-if="showButtonStart == true" @click="startConfirmation">
                  <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                  <span v-if="!vShow"> {{$t('start')}} </span>

                  <span v-else> {{$t('continue')}} </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline ml-6 w-6 stroke-current text-white stroke-2"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <line x1="5" y1="12" x2="19" y2="12" />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- _____________________________________________________ 1 page Confirmation _____________________ -->
    <div v-if="order._id && showButtonStart == false" class="font-sans bg-grey-lighter flex flex-col min-h-screen w-full">
      <!-- _______________________________________________________Section chrono && confirmation ___________________________________ -->
        <div class="container mx-auto px-40 py-4 pt-6">
            <!--Conter -->
            <div id="gettingValuesExample">
              <div class="flip-clock">
                  <span class="flip-clock__piece flip">
                      <span class="flip-clock__card flip-card">
                        <b class="flip-card__top hours">0</b>
                      </span>
                    <span class="flip-clock__slot">Hours</span>
                  </span>
                  <span class="flip-clock__piece flip">
                      <span class="flip-clock__card flip-card">
                        <b class="flip-card__top minutes">0</b>
                      </span>
                    <span class="flip-clock__slot">minutes</span>
                  </span>    
                  <span class="flip-clock__piece flip">
                      <span class="flip-clock__card flip-card">
                        <b class="flip-card__top seconds">0</b>
                      </span>
                    <span class="flip-clock__slot">seconds</span>
                  </span>        
              </div>  
            </div>
       
        </div>
       
      <!-- _____________________________________________End section chrono && conformation____________________________ -->

      <!-- ______________________________________________ Debut Section detail order  ___________________________________ -->

      <div class="flex-grow container mx-auto px-4 pb-8">
        <div class="w-full">
          <div class="card-1">
                <div class="card-header-1">
                    <div class="d-flex">
                        <h5 class="card-title flex-grow-1 mb-0"><svg class="inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14zm-6.793-4v-.757c1.529-1.381 2.425-2.116 2.436-2.896 0-.436-.255-.782-.872-.782-.46 0-.864.23-1.144.437l-.354-.889c.396-.304 1.029-.551 1.745-.551 1.218 0 1.875.708 1.875 1.67 0 1.145-1.043 1.996-1.917 2.724v.016h2.024v1.028h-3.793zm-4.097-3.544h1.11l.624.925.617-.925h1.075l-1.164 1.659 1.31 1.885h-1.11l-.741-1.118-.74 1.118h-1.091l1.275-1.865-1.165-1.679z"/></svg>{{$t('Double orders')}}</h5>
                        <div class="flex-shrink-0">({{dataDouble.length}})</div>
                    </div>
                </div>
                <div class="card-body-1">
                    <div class="text-center">
                      <svg class="inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-black" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path d="M18 6v-6h-18v18h6v6h18v-18h-6zm-12 10h-4v-14h14v4h-10v10zm16 6h-14v-14h14v14zm-6.793-4v-.757c1.529-1.381 2.425-2.116 2.436-2.896 0-.436-.255-.782-.872-.782-.46 0-.864.23-1.144.437l-.354-.889c.396-.304 1.029-.551 1.745-.551 1.218 0 1.875.708 1.875 1.67 0 1.145-1.043 1.996-1.917 2.724v.016h2.024v1.028h-3.793zm-4.097-3.544h1.11l.624.925.617-.925h1.075l-1.164 1.659 1.31 1.885h-1.11l-.741-1.118-.74 1.118h-1.091l1.275-1.865-1.165-1.679z"/></svg>
                        <h5 class="fs-16 mt-2">
                          <span v-if="loadingDouble">
                            {{$t('Checking')}} ...   <div class="facebook-spinner">
                              <div class="spinner-block block-1"></div>
                              <div class="spinner-block block-2"></div>
                              <div class="spinner-block block-3"></div>
                            </div>
                          </span>
                          <span v-else>
                            {{$t('Number of order doubled by this phone')}} ({{dataDouble.length}}) <span v-if="dataDouble.length>0"><svg @click="$vm2.open('ModalDouble')" class="cursor-pointer  inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-black"  width="24" height="24"   clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 5c-4.078 0-7.742 3.093-9.853 6.483-.096.159-.145.338-.145.517s.048.358.144.517c2.112 3.39 5.776 6.483 9.854 6.483 4.143 0 7.796-3.09 9.864-6.493.092-.156.138-.332.138-.507s-.046-.351-.138-.507c-2.068-3.403-5.721-6.493-9.864-6.493zm.002 3c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 1.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5z" fill-rule="nonzero"/></svg></span>
                          </span>
                        </h5>
                        <p class="phone mb-0">{{$t('Phone number')}}: {{order.customer.phone}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
          class="bg-white border-t border-b sm:border-l sm:border-r sm:rounded shadow"
        >
          <div class="border-b">
            <div class="flex justify-between">
              <div class="flex justify-between card-header-1">
                <h5
                  class="card-title flex-grow-1 mb-0"
                >
                <svg class="inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.25 7c0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25.56-1.25 1.25-1.25 1.25.56 1.25 1.25zm10.75 5c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-2 0c0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10 10-4.486 10-10zm-13-2v2h2v6h2v-8h-4z"/></svg> {{$t('detail_order')}}
                </h5>
              </div>
              <!--<div class="flex text-sm py-1">
                <div class="grid grid-cols-3 gap-2">
                  <button
                    v-if="edit"
                    class="material-icons flex items-center border hover:text-white hover:bg-pink-600 border-pink-600 text-pink-600 focus:outline-none max-w-max shadow-sm hover:shadow-lg rounded-full w-12 h-12"
                    v-on:click="activateInEditMode"
                  >
                    edit
                  </button>
                  <button
                    v-if="!edit"
                    class="material-icons flex items-center border hover:text-white hover:bg-yellow-600 border-yellow-600 text-yellow-600 focus:outline-none max-w-max shadow-sm hover:shadow-lg rounded-full w-12 h-12"
                    v-on:click="updateData(order)"
                  >
                    done
                  </button>
                  <button
                    v-if="!edit"
                    class="uppercase p-2 flex items-center border focus:outline-none hover:text-white hover:bg-red-600 border-red-600 text-red-600 max-w-max shadow-sm hover:shadow-lg rounded-full w-12 h-12"
                    v-on:click="desactivateInResetMode"
                  >
                    <svg
                      width="32"
                      height="32"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 32 32"
                      style="transform: rotate(360deg)"
                    >
                      <path d="M12 12h2v12h-2z" fill="currentColor"></path>
                      <path d="M18 12h2v12h-2z" fill="currentColor"></path>
                      <path
                        d="M4 6v2h2v20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8h2V6zm4 22V8h16v20z"
                        fill="currentColor"
                      ></path>
                      <path d="M12 2h8v2h-8z" fill="currentColor"></path>
                    </svg>
                  </button>
                </div>
              </div>-->
            </div>
          </div>

          <div class="flex md:flex-row flex-col m-auto my-5 mx-4">
            <div class="md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3">
              <div class="w-16 h-16 p-2">
                <i class="material-icons icon-call">bookmark_border</i> 
              </div>
              <div class="ml-2">
                <div class="text-2xl uppercase leading-8"> {{$t('order')}}</div>
                <div>{{ order.id }}</div>
              </div>
            </div>
            <div class="md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3">
              <div class="w-16 h-16 p-2">
                <i class="material-icons icon-call">date_range</i> 
              </div>
              <div class="ml-2">
                <div class="text-2xl uppercase leading-8"> {{$t('order_date')}}</div>
                <div>{{ order.date }}</div>
              </div>
            </div>
             <div class="md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3">
              <div class="w-16 h-16 p-2">
                <i class="material-icons icon-call">contacts</i> 
              </div>
              <div class="ml-2">
                <div class="text-2xl uppercase leading-8"> {{$t('seller')}}</div>
                <div>{{ order.seller.fullName||order.seller.email }}</div>
              </div>
            </div>
            <div class="md:w-1/4 p-4 flex justify-center items-center bg-gray-200 mx-2 rounded box-3">
              <div class="w-16 h-16 p-2">
                <i class="material-icons icon-call">local_grocery_store</i> 
              </div>
              <div class="ml-2">
                <div class="text-2xl uppercase leading-8">{{$t('store')}}</div>
                <div>{{ order.source }}</div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order.remind && !loading" id="alert-border-1" class="flex btn-marketing p-4 mt-4 text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:bg-gray-800 dark:border-blue-800" role="alert">
          <svg class="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <div class="ml-3 text-sm font-medium">
             Cette commande est déjà reportée pour la date {{ $moment(order.remind).format('yyyy-MM-DD HH:mm') }}.
             <p v-if="order.comment"><b>Raison:</b> {{ order.comment }}</p>
          </div>
         
      </div>

        <!-- ________________________________________________End Detail Order ________________________ -->

        <div class="flex flex-wrap -mx-4 py-4 mb-6">
          <div class="w-full mb-6 lg:mb-0 lg:w-1/2 px-4 flex flex-col">
            <div
              class="flex-grow flex flex-col bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
            >
              <div class="flex justify-between ">
                <div class="flex justify-between card-header-1  -mb-px">
                  <h5
                    class="card-title flex-grow-1 mb-0"
                  >
                  <svg class="inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23 6.066v12.065l-11.001 5.869-11-5.869v-12.131l11-6 11.001 6.066zm-21.001 11.465l9.5 5.069v-10.57l-9.5-4.946v10.447zm20.001-10.388l-9.501 4.889v10.568l9.501-5.069v-10.388zm-5.52 1.716l-9.534-4.964-4.349 2.373 9.404 4.896 4.479-2.305zm-8.476-5.541l9.565 4.98 3.832-1.972-9.405-5.185-3.992 2.177z"/></svg>  {{$t('order_products')}}
                  </h5>
                </div>
              </div>

              <div class="antialiased font-san">
                <div class="container mx-auto">
                  <div class="overflow-x-auto">
                    <div
                      class="inline-block min-w-full rounded-lg overflow-hidden"
                    >
                      <table class="min-w-full leading-normal">
                        <thead>
                          <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th
                              class="py-2 px-2 text-left"
                            >
                               {{$t('product')}}
                            </th>
                            <th
                              class="py-2 px-2 text-left"
                            >
                               {{$t('quantity')}}
                            </th>
                            <th
                              class="py-2 px-2 text-left"
                            >
                               {{$t('unit_price')}}
                            </th>
                            <th
                              class="py-2 px-2 text-left"
                            >
                               {{$t('Page produit')}}
                            </th>
                             <th
                              class="py-2 px-2 text-left"
                            >
                               {{$t('action')}}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(it, indx) in order.details"
                          :key="it._id"
                        >
                          <tr class="border-b border-gray-200 hover:bg-gray-100" :class="indx % 2 == 0 ? 'bg-gray-50' : ''">
                            <td
                              class="py-2 px-2 text-left"
                            >
                              <div class="flex items-center">
                                <div class="flex-shrink-0 w-10 h-10">
                                  <img
                                    alt="..."
                                    :src="it.product.picture?it.product.picture:'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'"
                                    class="h-10 w-10 rounded-md mx-auto"
                                  />
                                </div>
                                <div class="ml-3">
                                    {{ it.product.name }} 
                                    <div v-if="checkUpselExist(it.product)" @click="openModal('upsell', it.product.name,checkUpselExist(it.product))" class="d-inline-flex border border-gray-600 border-dashed pl-1 animation-pulse cursor-pointer">
                                        <b style="font-size: 15px;" class="text-red-700">Up-Sells</b> <img class="h-8 w-8 inline float-right rounded-full object-cover mx-1" src="https://img.icons8.com/stickers/100/sale-price-tag.png" alt="avatar">
                                    </div>
                                </div>
                              </div>
                            </td>
                            <td
                              class="py-2 px-2 text-left"
                            >
                              <p class="text-gray-900 whitespace-no-wrap">
                                {{ it.quantity }}
                              </p>
                            </td>
                            <td
                              class="py-2 px-2 text-left"
                            >
                              <p class="text-gray-900 whitespace-no-wrap">
                                {{ it.unitPrice?it.unitPrice.toFixed():0 }} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup>
                              </p>
                            </td>
                            <td
                              class="py-2 px-2 text-center"
                            >

                            <!-- <span class="bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full text-xs"><a
                                  :href="it.product.verificationLink" target="_blank"
                                  class="no-underline relative text-blue-600"
                                  >{{$t('view_more_details')}}
                                </a></span> -->
                                <a v-if="it.product.verificationLink && $f.validURL(it.product.verificationLink)" :href="it.product.verificationLink" target="_blank" class="no-underline relative text-blue-600"><svg  class="fill-current inline-block" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z"/></svg></a>
                                <button v-else type="button" class="text-white bg-yellow-800 hover:bg-yellow-900 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-yellow-300 dark:text-gray-800 dark:hover:bg-yellow-400 dark:focus:ring-yellow-800">
                                  Non défini
                                </button>
                            </td>
                             <td
                              class="py-2 px-2 text-left"
                            >
                              <p class="text-gray-900 whitespace-no-wrap cursor-pointer">
                               <i class="material-icons mx-2" @click="$vm2.open('updateorders')"> edit</i>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="px-5 py-5 bg-white flex flex-col xs:flex-row items-center xs:justify-between"
                >
                  <div class="inline-flex mt-2 xs:mt-0">
                    <span class="font-bold text-green-700 p-1">
                     {{$t('total_price')}} :</span
                    >
                    <span class="rounded-full ml-1 p-1 bg-red-100 text-green-700">
                      <span
                        class="justify-center text-center p-1 w-auto rounded-full font-bold"
                      >
                        {{  order.totalPrice?order.totalPrice.toFixed():0 }} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- __________________________________________________________End Product Order __________________________________________ -->
          <!-- __________________________________________________________Debut Customer Information ________________________________ -->

          <div class="w-full lg:w-1/2 px-4">
            <div class="bg-white border-t border-b sm:rounded sm:border shadow">
              <div class="border-b">
                <div class="flex justify-between card-header-1  -mb-px">
                  <h5
                    class="card-title flex-grow-1 mb-0"
                  >
                  <svg class="inline-block fill-current mdi mdi-truck-fast-outline align-middle me-1 text-muted" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M20.628 23h-6.59l-.352-1-13.682-.014c-.005-.005-.002-2.925-.004-3.165-.006-1.898-.012-3.538 2.784-4.182l.474-.108c1.504-.34 3.374-.762 3.724-1.533.041-.092.168-.371-.152-.961-1.926-3.548-2.293-6.665-1.035-8.777.856-1.436 2.42-2.26 4.289-2.26 1.854 0 3.407.812 4.26 2.226 1.258 2.086.901 5.214-1.004 8.807-.314.594-.186.873-.144.966.151.329.571.591 1.125.812l1.96-3.548c.106-.172.286-.263.469-.263.428 0 .694.471.469.837l-1.753 3.173h4.03l-1.752-3.173c-.225-.366.041-.837.469-.837.183 0 .363.091.469.263l2.069 3.747h3.249v2.983h-.828c-.083 0-.156.046-.192.119l-2.352 5.888zm2.371-7.99h-10.999v.996c.396.056.716.302.895.67l1.855 5.325h5.199l2.117-5.291c.188-.391.539-.645.933-.703v-.997zm-10.273-1c-.186-.175-.336-.371-.439-.595-.25-.544-.193-1.166.168-1.849 1.734-3.272 2.101-6.05 1.032-7.824-.677-1.124-1.885-1.742-3.403-1.742-1.53 0-2.748.629-3.429 1.772-1.071 1.797-.697 4.563 1.054 7.789.371.681.432 1.303.184 1.849-.555 1.223-2.517 1.667-4.414 2.096l-.471.107c-1.94.447-2.014 1.256-2.007 3.204l-.002 2.183h12.347l-1.35-3.942c-.013-.019-.086-.065-.168-.065h-.829v-2.983h1.727zm3.273 3.029c0-.276-.224-.5-.5-.5s-.5.224-.5.5v3c0 .276.224.5.5.5s.5-.224.5-.5v-3zm2-.074c0-.276-.224-.5-.5-.5s-.5.224-.5.5v3c0 .276.224.5.5.5s.5-.224.5-.5v-3zm1.997 0c0-.276-.224-.5-.5-.5-.275 0-.5.224-.5.5v3c0 .276.225.5.5.5.276 0 .5-.224.5-.5v-3z"/></svg> {{$t('customer_information')}}
                  </h5>
                </div>
              </div>
              <div>
                <div class="text-center px-6 py-4">
                  <div class="relative">
                    <div
                      class="icon bg-green-600 text-white w-6 h-6 absolute flex items-center justify-center p-5"
                      style="left: -40px"
                    >
                      <i class="fal fa-phone-volume fa-fw text-2xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="fill-current h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                            d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                          />
                        </svg>
                      </i>
                    </div>
                    <h5 class="text-2xl text-gray-900 font-semibold pb-6">
                      {{$t('call_customer')}}
                    </h5>
                    <div class="grid lg:grid-cols-2 gap-6">
                      <!-- ******************************** FULL Name && Address *********************************** -->
                      <div
                        class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                        :class="
                          style
                            ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                            : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                        "
                      >
                        <div
                          class="-mt-4 absolute tracking-wider px-1 uppercase text-xs"
                        >
                          <p>
                            <label
                              class="capitalize bg-white text-gray-600 px-1"
                              >  {{$t('full_name')}} *</label
                            >
                          </p>
                        </div>
                        <p>
                          <input
                            id="name"
                            autocomplete="false"
                            tabindex="0"
                            type="text"
                            disabled
                            :placeholder="$t('full_name')"
                            v-model="order.customer.fullName"
                            :class="
                              style
                                ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                                : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                            "
                          />
                        </p>
                      </div>
  <div
                        class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                        :class="
                          style
                            ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                            : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                        "
                      >
                        <div
                          class="-mt-4 absolute tracking-wider px-1 uppercase text-xs"
                        >
                          <p>
                            <label
                              for="phone"
                              class="capitalize bg-white text-gray-600 px-1"
                              >{{$t('phone')}} *</label
                            >
                          </p>
                        </div>
                        <p>
                          <input
                            autocomplete="false"
                            tabindex="0"
                            type="tel"
                            disabled
                            v-model="order.customer.phone"
                            :class="
                              style
                                ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                                : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                            "
                          />
                        </p>
                      </div>
                      <div
                        class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                        :class="
                          style
                            ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                            : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                        "
                      >
                        <div
                          class="-mt-4 absolute tracking-wider px-1 uppercase text-xs"
                        >
                          <p>
                            <label
                              for="address"
                              class="capitalize bg-white text-gray-600 px-1"
                              >{{$t('adress')}} *</label
                            >
                          </p>
                        </div>
                        <p>
                          <textarea
                            autocomplete="false"
                           
                            type="text"
                            disabled
                            :placeholder="$t('adress')"
                            v-model="order.customer.address"
                            :class="
                              style
                                ? 'py-1 px-1  text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                                : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                            "
                          />
                        </p>
                      </div>
                      <!-- *************************************************END FULL NAME && Address ********************************-->
                      <!-- *************************************************Debut section Phone ********************************-->
                    
            
                      
                  <!--    <div
                        class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1"
                        :class="
                          style
                            ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                            : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                        "
                      >
                        <div
                          class="-mt-4 absolute tracking-wider px-1 uppercase text-xs"
                        >
                          <p>
                            <label
                              for="password"
                              class="capitalize bg-white text-gray-600 px-1"
                              >Date shipping *</label
                            >
                          </p>
                        </div>
                        <p>
                          <input
                            type="date"
                            placeholder="Telephone"
                            disabled
                            v-model="shipping.date"
                            :class="
                              style
                                ? 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none border-blue-500 hover:border-blue-600'
                                : 'py-1 px-1 text-sm outline-none block h-full w-full focus:outline-none'
                            "
                          />
                        </p>
                      </div>-->
                      <!-- *************************************************END section Phone ********************************-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- __________________________________________________________End Customer __________________________________________ -->
        </div>
        <!-- ______________________________________________________________Debut section Button ____________________________ -->

        <div class="">
          <div class="flex justify-center w-full space-x-2">
            <button
              @click="open('modal-confirm')"
              class="w-1/6 p-3 rounded bg-green-600 hover:bg-green-500 text-white font-semibold focus:outline-none"
            >
               {{$t('confirm')}}
            </button>
            <template v-if="currentUser && !order.isCancelled && (currentUser.type=='TeleConsultant' ||  currentUser.type=='ToRemind')">
              <button  @click="$vm2.open('myModalUnrached')"
                class="w-1/6 p-3 rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"
              >
                {{$t('unreached')}}
              </button>
              <button @click="$vm2.open('myModalRemonded')"
                class="w-1/6 p-3 rounded bg-purple-600 hover:bg-purple-500 text-white font-semibold focus:outline-none"
              >
              {{$t('to_remind')}}
              </button>
              <button @click="OrangeOrderStatus(order)"
                class="w-1/6 p-2 rounded bg-yellow-600 hover:bg-yellow-500 text-white font-semibold focus:outline-none"
              >
                Orange money
              </button>
            </template>
            <button @click="$vm2.open('myModalCancel')"
              class="w-1/6 p-2 rounded bg-red-600 hover:bg-red-500 text-white font-semibold focus:outline-none"
            >
               {{$t('cancelled')}}
            </button>
           <!-- <button
              @click="skipOrder(order)"
              class="w-1/6 p-2 rounded bg-orange-600 hover:bg-orange-500 text-white font-semibold focus:outline-none"
            >
              Skip
            </button>-->
          </div>
        </div>
        <!-- _______________________________________________Fin ______________________________ -->
        <!-- ____________________________________Debut section PopUp Confirmation _________________ -->

         <vue-modal-2 name="modal-confirm" :headerOptions="{title: $t('confirmation_order')}" :noFooter="true" modalSize="lg" @on-close="CloseModal('modal-confirm')">
              <div class="p-4 pt-0 relative">
               <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                  <div>
                      <div class="flex flex-col md:flex-row">
                          <div class="w-full mx-2 flex-1 svelte-1l8159u">
                           <label for="shipp-date" class="font-bold h-6 mt-3 text-gray-800 text-xs leading-8 uppercase"> {{$t('shipping_date')}}</label>                 
                             <datetime-v2 :min-datetime="$moment(Date.now()).format('yyyy-MM-DD')" v-model="shipping.date"
                                       :placeholder="$t('shipping_date')" input-class="focus:outline-none shadow appearance-none border-2 border-gray-500 rounded w-full py-2 px-3 text-grey-darker" ></datetime-v2> 
                             
                              <label  class="font-bold h-6 mt-3 text-gray-800 text-xs leading-8 uppercase"> {{$t('city')}}</label>
                              <div  class="shadow bg-white my-2 p-1 flex border-2 border-gray-500 rounded svelte-1l8159u">
                                      <v-select  v-model="order.customer.city" :options="cities"></v-select>
                              </div>
                          </div>
                          <div class="w-full mx-2 flex-1 svelte-1l8159u">
                             <label class="font-bold h-6 mt-3 text-gray-800 text-xs leading-8 uppercase">  {{$t('phone_2')}}</label>
                             <input type="tel"  :placeholder="$t('telephone')" v-model="order.customer.shippingPhone" class="shadow appearance-none border-2 border-gray-500 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"> 
                              <label for="shipp-add" class="font-bold h-6 mt-3 text-gray-800 text-xs leading-8 uppercase"> {{$t('shipping_address')}}</label>
                              <textarea id="shipp-add" v-model="order.customer.shippingAddress"  cols="10" rows="5" :placeholder="$t('shipping_address_hint')" class="my-2 p-1 shadow appearance-none border-2 border-gray-500 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea> 
                        <!--  <label  class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"> {{$t('zone')}}</label>
                          <div  class="shadow bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
                                  <v-select label="name"  v-model="shipping.zone" :options="zones"></v-select>
                          </div>
                          <label class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"> {{$t('courier')}}</label>
                              <div class="shadow bg-white my-2 p-1 flex border border-gray-200 rounded svelte-1l8159u">
                                  <v-select label="fullName" @search="searchCouriers($event,'hh')" v-model="shipping.courrier" :options="courier"></v-select>
                              </div>-->
                            
                          </div>
                      </div>
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                      <label class="font-bold h-6 mt-3 text-gray-800 text-xs leading-8 uppercase">{{$t('add_comment')}}</label>
                    <div class="velte-1l8159u" style="margin-right: 15px;">
                                  <textarea
                      id="commentConfirmation"
                      cols="10"
                      rows="3"
                      :placeholder="$t('comment_hint')"
                      v-model="order.comment"
                      class="my-2 p-1 shadow appearance-none border-2 border-gray-500 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></textarea>
                    </div>
                  </div>
                  </div>
                  <div class="flex p-2 mt-4">
                      <div class="flex-auto flex flex-row-reverse">
                          <button @click="ConfirmOrderStatus(order)" :disabled="loading" class="w-1/6 p-3 rounded bg-green-600 hover:bg-green-500 text-white font-semibold focus:outline-none">{{$t('confirm')}}</button>
                      </div>
                  </div>
              </div>
         </vue-modal-2>

         
        <!-- ___________________________________________________________Debut section PopUp _________________ -->
      <!--Modal New courier Shipping -->
          <vue-modal-2  name="updateorders" :headerOptions="{title: 'Update order'}" :noFooter="true" modalSize="lg" @on-close="CloseModal('updateorders')">
            <div class="bg-white rounded-lg">
                <div class="rounded-lg flex">
                <div class="w-1/4 flex justify-center items-center">
                    <span class="w-16 h-16 bg-green-600 text-white p-3 rounded-full"><i class="material-icons" style="font-size: 40px;">update</i></span>
                </div>
                <div class="w-full pr-4 mt-4">
                  <div  v-for="(it, indx) in order.details" :key="indx" class="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mt-4">
                        <div class="grid grid-cols-1">
                          <div class="flex items-center maxx-heigh"><div class="flex-shrink-0 w-10 h-10"><img alt="..." :src="it.product.picture?it.product.picture:'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'" class="h-10 w-10 rounded-md mx-auto"></div><div class="ml-3"> {{it.product.name}} </div></div>
                        </div>
                        <div class="grid grid-cols-1">
                            <div class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                      <p>
                                          <label for="fullname" class="bg-white text-gray-700 px-1">{{$t('unit_price')}} <sup v-if="idWharhouse">({{idWharhouse.currency}})</sup></label>
                                      </p>
                                  </div> 
                                  <p>
                                      <input type="number"  v-model="it.unitPrice" class="py-2 text-xs px-2 outline-none block h-full w-full"> 
                                  </p>
                              </div> 
                        </div>
                        <div class="grid grid-cols-1">
                              <div class="border hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                                  <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                                      <p>
                                          <label for="Email" class="bg-white text-gray-700 px-1">{{$t('quantity')}}</label>
                                      </p>
                                  </div> 
                                  <p>
                                  <input type="number" v-model="it.quantity" class="py-2 text-xs px-2 outline-none block h-full w-full"> 
                                  </p>
                              </div> 
                        </div>
                  </div> 
                </div>
                </div>
                <div class="p-4 flex space-x-4">
                  <div @click="CloseModal('updateorders')" class="w-1/2 cursor-pointer px-4 py-3 text-center bg-gray-500 text-white hover:bg-gray-400  font-bold rounded-lg text-sm">{{$t('cancel')}}</div>
                  <div @click="UpdateOrder('updateorders')" class="w-1/2 cursor-pointer px-4 py-3 text-center text-blue-100 bg-green-600 rounded-lg hover:bg-green-700 hover:text-white font-bold text-sm loidingconfirm relative" :class="loadingupdateorder?'noselect':''"><loading :active.sync="loadingupdateorder" :is-full-page="fullPage"></loading>{{$t('confirme')}}</div>
                </div>
            </div>
          </vue-modal-2>   
        <!-- ____________________________________Debut section PopUp Cancelled _______________ -->
          <vue-modal-2 name="myModalCancel" :headerOptions="{title: $t('cancelation_order')}" :noFooter="true" modalSize="lg" @on-close="CloseModal('myModalCancel')">
              <div class="relative flex flex-col w-full h-auto">
                <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <!-- Header -->
              <!-- Modal Content-->
                <div class="bg-white rounded px-8 flex flex-col">
                  <div class="mb-3">
                  
                        <label class="block text-grey-darker text-sm font-bold mb-2" for="content">{{$t('reason_for_cancellation')}} <span :class="$colors.required">*</span></label>
                        <p>
                            <!-- <v-select  v-model="motifAnnulation" :options="comments"></v-select> -->
                            <textarea
                                id="content"
                                cols="10"
                                rows="3"
                                :placeholder="$t('content_hint')"
                                v-model="motifAnnulation"
                                class="focus:outline-none shadow appearance-none border border-gray-400 rounded w-full py-2 px-3 text-grey-darker mb-3"
                              ></textarea>
                        </p>     
                  
                    <div class="flex space-x-2">
                      <button
                        @click="cancelOrder(order)"
                        :disabled="loading"
                        class="w-full mt-2 bg-blue-600 focus:outline-none hover:bg-blue-500 text-white font-semibold p-3"
                      >
                        {{$t('cancel_order')}}
                      </button>
                    </div>
                  </div>
                </div>
                <!-- End of Modal Content-->
              </div>
          </vue-modal-2>
        <!-- ___________________________________________________________Debut section PopUp _________________ -->

          <!-- ____________________________________Debut section PopUp Remonded _______________ -->

        <vue-modal-2 name="myModalRemonded" :headerOptions="{title: $t('remonded_order')}" :noFooter="true" modalSize="lg" @on-close="CloseModal('myModalRemonded')">
         <div class="relative flex flex-col w-full h-auto">
            <loading :active.sync="loading" :is-full-page="fullPage"></loading>
            <!-- Header -->
            <!-- Modal Content-->
            <div class="bg-white rounded px-8 pt-6 pb-8 flex flex-col">
            <div class="mb-4">
              <label class="block text-grey-darker text-sm font-bold mb-2" for="dateremind">
                {{$t('remind_date')}} :
              </label>
              <!-- <input  v-model="remindDate" class="focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="dateremind" type="datetime-local" placeholder="date"> -->
             <datetime-v2 type="datetime" v-model="remindDate" :max-datetime="$moment(new Date()).add('days', 7).format()"
               :placeholder="$t('remind_date')" input-class="focus:outline-none shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" ></datetime-v2>
            </div>
              <div class="mb-6">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="content">{{$t('content')}}</label>
                  <textarea
                    id="content"
                    cols="10"
                    rows="3"
                    :placeholder="$t('content_hint')"
                    v-model="contentRemend"
                    class="focus:outline-none shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
                  ></textarea>
              </div>
              <div class="flex items-center justify-between">
                <button :disabled="loading" @click="RemindOrder(order)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" type="button">
                  {{$t('confirm')}}
                </button>
            </div>
            </div>
            <!-- End of Modal Content-->
          </div>
        </vue-modal-2>
        <!-- ___________________________________________________________Debut section PopUp _________________ -->
          <!-- ____________________________________Debut section PopUp Unrached ________________ -->

          <vue-modal-2 name="myModalUnrached" :headerOptions="{title: $t('unrached_order')}" :noFooter="true" modalSize="lg" @on-close="CloseModal('myModalUnrached')">
            <div class="flex flex-col w-full h-auto">
            <!-- Header -->
            <!-- Modal Content-->
            <div class="bg-white rounded px-8 pt-6 pb-8 flex flex-col">
               <loading :active.sync="loading" :is-full-page="fullPage"></loading>
              <div class="my-6">
                <div class="border seller-select h-10 hover:border-blue-400 focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
                    <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                        <p>
                          <label for="seller" class="bg-white text-gray-700 px-1"> {{$t('reason_unreached')}}
                          <span :class="$colors.required">*</span></label>
                        </p>
                    </div>    
                    <p>
                        <v-select  v-model="motifUnrached" :options="commentsUnrach"></v-select>
                    </p>     
                </div> 
              </div>
              <div class="flex items-center justify-between">
                <button :disabled="loading" @click="UnreachedOrder(order)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" type="button">
                 {{$t('confirm_unreached')}}
                </button>
            </div>
            </div>
            <!-- End of Modal Content-->
          </div>
          </vue-modal-2>

          <vue-modal-2 class="test"  name="ModalDouble" :headerOptions="{title: 'Double orders'}" :noFooter="true" modalSize="xl" @on-close="CloseModal('ModalDouble')">
            <div class="flex flex-col w-full h-auto p-5 pt-0  bottom_bitt">
                <div class="align-middle inline-block min-w-full overflow-hidden bg-white shadow-dashboard rounded-bl-lg rounded-br-lg" style="margin-bottom: 62px;">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left leading-4  text-green-600  tracking-wider">ID order</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('customer')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('products')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('total_price')}}</th>
                             <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('date')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-left text-sm leading-4  text-green-600  tracking-wider">{{$t('status')}}</th>
                            <th class="px-3 py-1 siz12 bg-gray-200 border-b-2 border-t-2 border-gray-300 text-right text-sm leading-4  text-green-600  tracking-wider">{{$t('action')}}</th>
                            
                        </tr>
                    </thead>
                        <tbody class="bg-white">
                        <tr v-for="(order,ind) in dataDouble" :key="ind">
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="flex items-center">
                                    <div>
                                        <div class="text-sm leading-5 text-gray-800">{{order.id}}</div>
                                    </div>
                                </div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 siz12 text-blue-900">
                                  <div class="flex-grow-1">
                                    <h5 class="fs-14 mb-1">{{order.customer.fullName}}</h5>
                                    <p class="badge badge-soft-success text-uppercase">{{order.customer.phone}}</p></div>
                                  
                                </div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 text-blue-900">{{sumProduct(order.details)}}</div>
                            </td>
                            <td class="px-3 py-1 text-left border-t-2 border-gray-300 whitespace-no-wrap">
                                <div class="text-sm leading-5 text-blue-900">{{order.totalPrice}} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup> </div>
                            </td>
                            <td class="px-3 py-1 text-left border-t-2 border-gray-300 whitespace-no-wrap">
                                <div class="text-sm leading-5 text-blue-900">{{$moment(order.date).format('yyyy-MM-DD') }}</div>
                            </td>
                             <td class="border-t-2 border-gray-300 px-3 py-1">
                                <div class="text-sm leading-5 text-blue-900" v-bind:style="{color: order.status.name?order.status.color:'',borderColor: order.status.name?order.status.color:''}">{{order.status.name}}</div>
                            </td>
                            <td class="border-t-2 border-gray-300 px-3 py-1 text-right">
                              <router-link target="_blank" :to="{ path: `/orders/detail/${order._id}`}" class="text-sm leading-5 px-2 rounded-full text-xs text-blue-900 cursor-pointer font-medium"><i class="material-icons mx-2"> remove_red_eye</i></router-link>
                            </td>
                           
                        </tr>
                        </tbody> 
                    </table>
                            
            </div>     
                
            </div>

            <div class="modal-footer px-5 border0-t text-right">
                <button @click="CloseModal('ModalDouble')" class="flex text-white mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right">Cancel</button>
            </div>
          </vue-modal-2>    

          <vue-modal-2 name="warehouseParent" :headerOptions="{title: 'Choisir la ville de stockage'}" :noFooter="true" modalSize="md">
            <div class="flex flex-col w-full h-auto">
            <!-- Header -->
            <!-- Modal Content-->
            <div class="bg-white rounded px-2  pb-2 flex flex-col">
               <loading :active.sync="loading" :is-full-page="fullPage"></loading>
                <p class="font-bold text-center">S'il vous plait Choisir la ville de stockage de produit</p>
                <div  class="shadow bg-white my-2 p-1  border-2 border-gray-500 rounded svelte-1l8159u">
                      <v-select  v-model="order.zone" :options="checkWraehouseParent" label="name"></v-select>
                </div>
                <div class="flex p-2 mt-4">
                      <div class="flex-auto flex flex-row-reverse">
                          <button :disabled="loading" @click="ConfirmOrderStatus(order,'warehouseParent')" class="w-1/3 p-3 btn-action rounded bg-blue-600 hover:bg-blue-500 text-white font-semibold focus:outline-none"> {{$t('confirm')}}</button>
                          <button :disabled="loading"  @click="CloseModal('warehouseParent')" class="w-1/3 p-3 mr-4 rounded bg-gray-600 hover:bg-gray-500 text-white font-semibold focus:outline-none">Annuler</button>
                      </div>
                  </div>
            </div>
            <!-- End of Modal Content-->
          </div>
         </vue-modal-2>

          <!--Modal Up-Sells setting -->
        <vue-modal-2  name="upsell" :headerOptions="{title: `Product: ${upsell.product}`}" :noFooter="true" modalSize="lg" @onclose="close('upsell')">
            <div class="bg-white rounded-lg">
                <div class="bg-white px-8 mt-4 pb-1 mb-20">
                    <div class="vstack gap-2"> 
                        <div  v-for="(upsel, index) in upsell.upsells" :key="index" @click="activate(upsel)" :class="{active: upsell.activeUpsel && upsell.activeUpsel._id && upsell.activeUpsel._id.toString() === upsel._id.toString()?true:false}" class="form-check card-radio zoom-in intro-y">
                            <label class="form-check-label" for="listGroupRadioGrid1"> 
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs">
                                            <div class="avatar-title bg-success-subtle text-success fs-18 rounded">
                                              <img class="h-8 w-8 rounded-full object-cover mx-1" src="https://img.icons8.com/stickers/100/sale-price-tag.png" alt="avatar">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ml-3">
                                        <h6 class="mb-1">Quantity: {{ upsel.quantity }}</h6>
                                        <b class="pay-amount">Prix: {{ upsel.price }} <sup v-if="idWharhouse">{{idWharhouse.currency}}</sup></b>
                                    </div>
                                </div>
                            </label>
                        </div>
                     </div>
                </div>    
                <div class="modal-footer px-5 border0-t text-right">
                    <div  @click="CloseModal('upsell')" class="flex text-white cursor-pointer mx-auto max-w-sm rounded text-center bg-gray-500 py-2 px-8 items-center focus:outline-none md:float-right" :class="loadingupdateorder?'noselect':''">Fermer</div>
                    <div @click="UpdateOrder('upsell')" class="flex text-white cursor-pointer mx-auto max-w-sm rounded text-center bg-green-500 py-2 px-8 items-center focus:outline-none md:float-right loidingconfirm relative mr-5" :class="loadingupdateorder?'noselect':''"><loading :active.sync="loadingupdateorder" :is-full-page="fullPage"></loading>Enregister Upsel</div>
                </div>
            </div>
        </vue-modal-2>  
        <!-- ___________________________________________________________Debut section PopUp _________________ -->
      </div>
    </div>

    <!-- <div
      v-if="finished && showButtonStart == false"
      class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-3 items-center"
    >
      <div class="bg-white w-full rounded-lg shadow">
        <div class="bg-white w-full rounded-lg shadow">
          <div class="flex justify-center">
            <p></p>
            <p
              class="justify-center font-semibold flex text-blue-500 whitespace-no-wrap"
            >
              <i class="material-icons mx-3">error</i>
              No order To Confirm
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
 var Timer = require("easytimer.js").Timer; 
 var timer = new Timer();
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
     sous_idWharhouse: { type: String},
     sous_warehouses: { type: Array },
   },
  data() {
    return {
      formData: {
        customer: {},
        details: [],
        seller: {},
      },
      zones: [],
      courier: [],
      loading:false,
      fullPage: false,
      couriers:[],
      shipping: {
        date: "",
        zone: null,
        courrier: null,
      },
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      statusOrder: [],
     // currentUser: {},
      order: [{
        customer: {},
        details: [],
        seller: {},
        teleConsultant: {},
        skippedBy: [],
        unreachedBy: [],
      }],
      showButtonStart: true,
      vShow: false,
      orderFound:false,
      finished: false,
      isEditing: false,
      style: false,
      edit: true,
      sec: 0,
      min: 0,
      hr: 0,
      passedTime: "",
      time:0,
      m: 0,
      s: 0,
      h: 0,
      dateDebut: "",
      cities:[],
      zeroH: false,
      zeroM: false,
      zeroS: false,
      motifAnnulation: "",
      motifUnrached:"",
      contentRemend:"",
      currency:"",
      remindDate:null,
      loadingupdateorder:false,
      dataCountry: this.$countries,
      comments:['Not interested-changed mind',"Didn't order","Fake phone number","Too expensive","Non delivery area","Out of country","Mistake","Bad comments","Multiple order"],
      commentsUnrach:["Boite vocale","Ne répond pas","Or ligne"],
      //idWharhouse:null,
      loadingDouble:false,
      dataDouble:[],
      checkWraehouseParent:[],
      upsell:{product:"",upsells:[],activeUpsel:{}},
      statusCancelled:null
    };
  },
  async mounted() {
    //await this.getUser();
    this.token = this.$jwtService.verifyToken();
    if(this.currentUser && (this.currentUser.type != 'TeleConsultant' && this.currentUser.type != 'NRP' && this.currentUser.type != 'CancelledOrder' && this.currentUser.type != 'ToRemind')) this.$router.back();
    this.statusCancelled=await this.getStatusByName("Cancelled");
    console.log('sous_warehouses -------',this.sous_warehouses)
    //await this.getwharhouseId();
   // await this.getStatus();
    // await this.getZones();
    // await this.getCouriers();

   
  },
  computed: {
      warhouseSelected(){
           return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
      },
   },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
        // await this.getwharhouseId();
        
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
           // await this.getZones();
            //await this.getCouriers();  
      }
  },
  methods: {
  checkUpselExist(product){
    let upselExist=false
    const checkUpsel=product.details.find(item => item.warehouse && item.warehouse && item.warehouse.toString() === this.idWharhouse._id.toString())
    if(checkUpsel && checkUpsel.upsells && checkUpsel.upsells.length>0){
      upselExist=checkUpsel.upsells;
    } 
    return upselExist;
  },
   async CloseModal(id){
      this.$vm2.close(id);
      this.upsell={product:"",upsells:[],activeUpsel:{}}
      if(id === 'updateorders'){
         const editData = await this.$server.get("orders", {id: this.order._id,getCurrentOrder:true});
         this.order.details=editData.content.details;
      }
    },
    async openModal(id,product=null,upsells=[]){
        if(id === 'upsell'){
          this.upsell={product:product,upsells:upsells,activeUpsel:{}}
        } 
        this.$vm2.open(id);
    },
    activate(upsel){
      console.log('this==============>',upsel)
      this.upsell.activeUpsel=upsel;
      console.log("activate activeUpsel --------", this.upsell.activeUpsel)
    },
    sumProduct(data){
      let product="";
      for (let i in data) {
        if(data[i].product && data[i].product.name) product+=data[i].product.name
        if(data.length-1 != i) product+=" ,"; 
      }
      return product;
    },
    async getwharhouseId(){
        await this.$store.dispatch('wharhouse/getwharhouseId',{country:await this.warhouseSelected}).then((res) => {
                this.idWharhouse=res;
            })
    },
    async getCurrency(){
        await this.$store.dispatch('wharhouse/getCurrency').then((res) => {
                this.currency=res;
            })
    },
    SumTotalPrice(data) {
       let total=0;
        for (let i in data) {
          total += data[i].quantity * data[i].unitPrice;
        }
        return total;
    },
   async UpdateOrder(name=null){
    console.log('order 11-------', this.order.details)
      if(name && name === 'upsell'){
          if(!this.upsell.activeUpsel._id){this.$toast.warning("SVP choisir un Pack Upsell");return false;} 
          const index = this.order.details.findIndex(obj => {return obj.product.details.find(item => item.warehouse && item.warehouse && item.warehouse.toString() === this.idWharhouse._id.toString()).upsells.find(el => el._id.toString() === this.upsell.activeUpsel._id.toString())._id.toString() === this.upsell.activeUpsel._id.toString()});
          console.log('index-------',index)
          this.order.details[index].unitPrice=this.upsell.activeUpsel.price/this.upsell.activeUpsel.quantity;
          this.order.details[index].quantity=this.upsell.activeUpsel.quantity;
      }

      for (let index = 0; index < this.order.details.length; index++) {
            const orderLine = this.order.details[index];
            const product = await this.$server.get("products",{  _id: orderLine.product._id } )
            const dataWhrahouse= this.getElementInArray(product.content.details,this.idWharhouse._id);
            if (dataWhrahouse.outOfStock || dataWhrahouse.quantity.inStock < orderLine.quantity) {
                return  this.$toast.warning(`Quantité en stock insuffisante pour ce produit: (${product.content.name}),(Stock disponible: ${dataWhrahouse.quantity.inStock})`);
                break;
            }
      }
      this.loadingupdateorder=true;
      this.order.totalPrice=this.SumTotalPrice(this.order.details);
      const resUpp= await this.$server.update("orders", this.order);
      this.loadingupdateorder=false;
      if(resUpp && resUpp._id) {alert(this.$t('order_updated'), "success");this.$vm2.close(name);this.upsell={product:"",upsells:[],activeUpsel:{}}} 
      else alert(resUpp, "warning"); 
    },
    getElementInArray(data,value){
      var result  = data.filter(function(o){
          let warhouse=o.warehouse;
          if(o.warehouse._id) warhouse=o.warehouse._id;
          
          return warhouse == value.toString();
      } );
      return result? result[0] : null; // or undefined
    },
    async getCities(country){
      const resCities = await this.$server.get("zones", { allCities: true,country:country}); 
      if(resCities && resCities.content.length>0)  this.cities= resCities.content;
      else this.cities=[];
    },
    dateMin(){ 
       //document.getElementById('shippdate').setAttribute('min', this.$moment(Date.now()).format('yyyy-MM-DD')); 
    },
    CalculateTime() {
      // this.passedTime =this.hr + "hr " + ":" + this.min + " min" + ": " + this.sec + "sec";
      // this.time=this.hr*3600 +  this.min*60 + this.sec;
      // clearTimeout(this.h);
      // clearTimeout(this.m);
      // clearTimeout(this.s);
      this.passedTime=timer.getTimeValues().toString();
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async open(name){
       this.$vm2.open(name);
       if(name== 'modal-confirm') await this.getCities(this.order.customer.country);
    },
    async assign(data) {
      const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
       let newdate=new Date(nDate)
     // if(!data.teleConsultant) {
        data.teleConsultant = this.currentUser._id;
        data.startDate=newdate;
        data.updateStartDate=true;
        data.updateTeleConsultant=true;
        await this.$server.update("orders", data);
        delete data.updateTeleConsultant;
     // }
    },
    async getOrderDoubled(country,phone,seller,product,id){
      this.loadingDouble=true;
      const data = await this.$server.search("orders",{'details.product':product,'customer.country':country,phone:phone,_id:id,seller:seller,limit:10});
      this.loadingDouble=false;
      if(data && data.content && data.content.results) this.dataDouble=data.content.results;
      else this.dataDouble=[]
      console.log('data-------',data)
    },
    async startConfirmation() {
      this.upsell={product:"",upsells:[],activeUpsel:{}}
      const country= await this.warhouseSelected;
      await this.getOrder(country);
      
       console.log("HIIII JE SUIS this.order", this.order);
       console.log("warehouse", this.idWharhouse);
      
      if (this.orderFound) {
       
       // await this.assign(this.order);

        timer.reset();
        timer.start();

        timer.addEventListener('secondsUpdated', function (e) {
            $('#gettingValuesExample .hours').html(('0' + timer.getTimeValues().hours).slice(-2));
            $('#gettingValuesExample .minutes').html(('0' + timer.getTimeValues().minutes).slice(-2));
            $('#gettingValuesExample .seconds').html(('0' + timer.getTimeValues().seconds).slice(-2));
        });

         document.body.style.overflow = 'inherit'

        this.showButtonStart = false;
        this.sec = 0;
        this.min = 0;
        this.hr = 0;
        const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
        let newdate=new Date(nDate)
        this.dateDebut = new Date();
        await this.getOrderDoubled(country,this.order.customer.phone,this.order.seller._id,this.order.details[0].product._id,this.order._id);

      } else  alert(this.$t('no_order_to_confirm'), "warning");
    },
    async getOrder(country) {
      console.log('---------------getOrder-------------')
      this.loading=true;
      const data = await this.$server.confirmation("orders",{country:country,dateNow:new Date().toLocaleString('en-US', { hour12: false }).replace(',','')});
      console.log('dat____________',data)
      if (typeof data.content != 'undefined' && data) {
        this.orderFound=true;
        this.order = data.content;
        this.order.date=this.$moment(this.order.date).format('yyyy-MM-DD HH:mm');
        if(this.order.status._id.toString() === this.statusCancelled._id.toString())  this.order.isCancelled=true;
        else this.order.isCancelled=null;

      } else {
        this.order=[{
          customer: {},
          details: [],
          seller: {},
          teleConsultant: {},
          skippedBy: [],
          unreachedBy: [],
      }],
        this.orderFound=false;
        this.finished = true;
      }
      this.loading=false;
    },
    //****************************** Zones  ***********************************/
    async getZones() {
      const res = await this.$server.search("zones",{limit:2000,country:await this.warhouseSelected});
      if (res.content.results) this.zones = res.content.results;
      else this.zones = [];
    },
     async getCouriers() {
        const filter = {
          type: "Courier",
          countries: await this.warhouseSelected,
          limit:this.limit
        };
        const res = await this.$server.search("users", filter);
        if (res.content.results) {
          this.courier = res.content.results;
        } else this.courier = [];
    },
    searchCouriers: _.debounce(async function(search) {
        const filter = {
            type: "Courier",
            limit:this.limit,
            countries:await this.warhouseSelected,
            name: search,
            };  
            const res= await this.$server.find("users",filter);   
        if (res.content.results) this.courier = res.content.results;
        else this.courier = [];
    }, 500),
    async getCourierZone(zone) {
      // if(zone){
      //   const filter = {
      //     type: "Courier",
      //     countries: await this.warhouseSelected,
      //     zones: zone._id,
      //     limit:10
      //   };
      //   const res = await this.$server.search("users", filter);
      //   if (res.content.results) {
      //     this.courier = res.content.results;
      //   } else this.courier = [];
      // }
    },
    // **************************************************************************
    async getStatus() {
      const res = await this.$server.search("status");
      if (res.content.results) this.statusOrder = res.content.results;
      else this.statusOrder = [];
    },
    async getStatusByName(name) {
        const result = await this.$server.get("status",{  name: name  });
        return result.content;
    },
    async getStatusId(status) {
      for (let i in this.statusOrder) {
        if (this.statusOrder[i].name == status) {
          return this.statusOrder[i]._id;
        }
      }
    },

    CreateShipping() {
      const data = {
        address: this.order.customer.shippingAddress,
        date: this.shipping.date,
        order: this.order._id,
      };
      //console.log("HIIIIIIIIIIIIIIII SE SUIS data", data);
      // const data = await this.$server.create("status", this.formData);
    },

    async ConfirmOrderStatus(dataToEdit,checkParent=null) {
      console.log('sous_warehouses -------',this.sous_warehouses)
     // return false;
     this.loading=true;
     if(!checkParent){
       this.checkWraehouseParent=[];
       //check stock if exist
       if(this.sous_warehouses.length>0){

          for (let index = 0; index < dataToEdit.details.length; index++) {
              const orderLine = dataToEdit.details[index];
              // orderLine.warehouseParent=this.idWharhouse._id;
              // orderLine.warehouseParentName=this.idWharhouse.name;
              const {content:product} = await this.$server.get("products",{  _id: orderLine.product._id } )
              //console.log('product -------',product)
              let dataWhrahouse=this.$f.getElementInArrayByCountry(product.details,this.idWharhouse.country,orderLine.quantity);
              if(dataWhrahouse && dataWhrahouse.length>0){
                 this.checkWraehouseParent=dataWhrahouse.map(i => i.warehouse);
                 if(this.checkWraehouseParent.length == 1){
                    orderLine.warehouseParent=this.checkWraehouseParent[0]._id;
                    orderLine.warehouseParentName=this.checkWraehouseParent[0].name;
                 }
              }else this.checkWraehouseParent=[] 
          }
       }

       if(this.checkWraehouseParent.length>1){
          this.loading=false;
          this.$vm2.open('warehouseParent');
          return false
       }

     }


      
      if(checkParent && !dataToEdit.zone){
          this.loading=false;
          alert('Ville de stockage required', "warning");
          return false
      }
      if(checkParent && dataToEdit.zone){
        for (let index = 0; index < dataToEdit.details.length; index++) {
              const orderLine = dataToEdit.details[index];
              orderLine.warehouseParent=dataToEdit.zone._id;
              orderLine.warehouseParentName=dataToEdit.zone.name;
        }
      }
       console.log('checkWraehouseParent -------',this.checkWraehouseParent)
       console.log('dataToEdit -------',dataToEdit)
       
     //return false;
      
      this.dateMin();
      let id = await this.getStatusByName("Confirmed");
      console.log('id_______',id);
      // console.log('status______',await this.$server.get("status"))
      dataToEdit.status = id;
      dataToEdit.statusCallcenter = id;
      this.CalculateTime();
      dataToEdit.timeConsumed = this.passedTime;
      dataToEdit.time = parseInt(this.time);
      dataToEdit.dateDebut = this.dateDebut;
      dataToEdit.teleConsultantConfirm = this.currentUser._id;

      if (
        this.shipping.date &&
        this.order._id
      ) {
        const datashipping = {
          address: this.order.customer.shippingAddress,
          country: this.order.customer.country,
          date: this.shipping.date,
          order: this.order._id,
          seller: this.order.seller._id,
          totalPrice:this.order.totalPrice,
          //courier: this.shipping.courrier._id,
          status: "to prepare",
      };
        dataToEdit.shipping = datashipping;
       
        console.log('dataToEdit_______',dataToEdit);
        dataToEdit.Confirmation=true;
        const data = await this.$server.update("orders", dataToEdit);
        this.loading=false;
        if (data && data._id) {
          this.$vm2.close('warehouseParent');
          alert(this.$t('order_confirmed'), "success");
           dataToEdit={};
          this.order = {
            customer: {},
            details: [],
            seller: {},
            teleConsultant: {},
            skippedBy: [],
          };
          this.shipping={}
          this.showButtonStart = true;
          this.vShow = true;
        }else{
          await this.$server.update("orders", {_id: this.order._id,teleConsultant:null,onlyCommentedit:true});  
          // let BodyDataOrder={_id: this.order._id,teleConsultant:null};
          //  if(dataOrr.message_1 && dataOrr.action && dataOrr.action=='Confirmation'){
          //     BodyDataOrder.warehouse=dataOrr.warehouse;
          //     BodyDataOrder.productId=dataOrr.productId;
          //     BodyDataOrder.ChangeToWaiting=true;
          //     BodyDataOrder.quantity=dataOrr.quantity;
          //  } 
          //   await this.$server.update("orders", BodyDataOrder); 
          this.refreshData(data.message_1||data, "warning")
        } 
      } else {
        alert(this.$t('all_required_field'), "warning");
      }
    },
     async OrangeOrderStatus(dataToEdit) {
     
      this.$confirm("Are You sure to change order to Orange money ?").then(async (res) => {
          if (res) {
            let id = await this.getStatusByName("Orange money");
            dataToEdit.status = id;
            dataToEdit.statusCallcenter = id;
            this.CalculateTime();
            dataToEdit.timeConsumed = this.passedTime;
            dataToEdit.time = parseInt(this.time);
            dataToEdit.dateDebut = this.dateDebut;
            dataToEdit.teleConsultantConfirm = this.currentUser._id;
            dataToEdit.transfer = true;
            const data = await this.$server.update("orders", dataToEdit);
            if (data && data._id) {
               this.refreshData('Order updated', "success")
            }else{
              await this.$server.update("orders", {_id: this.order._id,teleConsultant:null,onlyCommentedit:true});  
              this.refreshData(data, "warning");
            }  
          }
        });
    },
   async RemindOrder(dataToEdit){
      this.loading=true;
      dataToEdit.comment=this.contentRemend;
      
      dataToEdit.remind=this.remindDate;
      this.CalculateTime();
      dataToEdit.timeConsumed = this.passedTime;
      dataToEdit.time = parseInt(this.time);
      dataToEdit.dateDebut = this.dateDebut;
      const element = {
        user: this.currentUser._id,
      };

      dataToEdit.remindBy.push(element);
      dataToEdit.teleConsultant = null;
      dataToEdit.reminded = true;
      dataToEdit.lastUnreachedDate = null;
      if (!this.remindDate) {
        alert(this.$t('reminder_date_empty'), "warning");
        this.loading=false;
        return false;
      }

      
       console.log('date____', dataToEdit.remind)
      const data = await this.$server.update("orders", dataToEdit);
      this.loading=false;
      if (data && data._id) {
        alert(this.$t('order_reminded'), "success");
        this.remindDate=null;
        this.contentRemend="";
        dataToEdit={};
        this.order = {
          customer: {},
          details: [],
          seller: {},
          teleConsultant: {},
          remindBy: [],
          unreachedBy: [],
        };
        this.showButtonStart = true;
        this.vShow = true;
      }else{
      await this.$server.update("orders", {_id: this.order._id,teleConsultant:null,onlyCommentedit:true});  
        dataToEdit={};
        this.remindDate=null;
        this.contentRemend="";
        this.order = {
          customer: {},
          details: [],
          seller: {},
          teleConsultant: {},
          remindBy: [],
          unreachedBy: [],
        };
        this.showButtonStart = true;
        this.vShow = true;
        alert(data, "warning");
      }
    },
    async skipOrder(dataToEdit) {
      let id = await this.getStatusByName("Skipped");
      const element = {
        user: this.currentUser._id,
      };

      dataToEdit.skippedBy.push(element);
      dataToEdit.skiped = id;
      dataToEdit.teleConsultant = null;

      this.$confirm(this.$t('skip_order')).then(async (res) => {
        if (res) {
          this.CalculateTime();
          dataToEdit.timeConsumed = this.passedTime;
          dataToEdit.time = parseInt(this.time);
           dataToEdit.teleConsultantConfirm = this.currentUser._id;
          dataToEdit.dateDebut = this.dateDebut;
          const data = await this.$server.update("orders", dataToEdit);
          if (data._id) {
            alert(this.$t('order_skipped'), "warning");
            this.order = {
              customer: {},
              details: [],
              seller: {},
              teleConsultant: {},
              skippedBy: [],
              unreachedBy: [],
            };
            this.showButtonStart = true;
            this.vShow = true;
          }
        }
      });
    },
    /************************************************* */
    async cancelOrder(dataToEdit) {
       this.loading=true;
       const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
       let newdate=new Date(nDate)
       let id =  await this.getStatusByName("Cancelled");

      if(dataToEdit.isCancelled) dataToEdit.validated_status=new Date();

      dataToEdit.status = id;
      dataToEdit.statusCallcenter = id;
      dataToEdit.motifAnnulation = this.motifAnnulation;
      this.CalculateTime(); 
      dataToEdit.timeConsumed = this.passedTime;
      dataToEdit.time = parseInt(this.time);
      dataToEdit.teleConsultantConfirm = this.currentUser._id;
      dataToEdit.dateDebut = this.dateDebut;
      dataToEdit.comment=this.motifAnnulation; 
      dataToEdit.teleConsultant = null;

      if(!this.motifAnnulation){
        this.loading=false;
          alert(this.$t('reason_cancelled_required'), "warning");
          return false
      }
       
       dataToEdit.cancelOrder=true;
      const data = await this.$server.update("orders", dataToEdit);
      this.loading=false;
      if (data && data._id) {
        this.motifAnnulation="";
        alert(this.$t('order_cancelled'), "success");
         dataToEdit={};
        this.order = {
          customer: {},
          details: [],
          seller: {},
          teleConsultant: {},
          skippedBy: [],
          unreachedBy: [],
        };
        this.showButtonStart = true;
        this.vShow = true;
      }else {
        await this.$server.update("orders", {_id: this.order._id,teleConsultant:null,onlyCommentedit:true});  
        alert(data,'warning')
      }
    },

    /************************************************************/

   async UnreachedOrder(dataToEdit) {
          this.loading=true;
          let id =  await this.getStatusByName("Unreached");
          const nDate = new Date().toLocaleString('en-US', {timeZone: 'Africa/Casablanca'});
          let newdate=new Date(nDate)
          dataToEdit.unreached = id;
          dataToEdit.teleConsultant = null;
          dataToEdit.lastUnreachedDate = newdate;

          if(dataToEdit.remind){
             // dataToEdit.isReminded=true;
              dataToEdit.remind=null;
              dataToEdit.isReminded=false;
          }
          this.CalculateTime();
          dataToEdit.timeConsumed = this.passedTime;
          dataToEdit.time = parseInt(this.time);
          dataToEdit.dateDebut = this.dateDebut;
          dataToEdit.comment=this.motifUnrached;
          dataToEdit.dateUnrash = newdate;

          delete dataToEdit.updateStartDate;

          if(!this.motifUnrached){
            this.loading=false;
             alert(this.$t('reason_unreached_required'), "warning");
             return false
          }

         

          const data = await this.$server.update("orders", dataToEdit);
          this.loading=false;
          if (data && data._id) {
            alert(this.$t('order_unreached'), "success");
            this.motifUnrached="";
            this.order = {
              customer: {},
              details: [],
              seller: {},
              teleConsultant: {},
              skippedBy: [],
              unreachedBy: [],
            };
            this.showButtonStart = true;
            this.vShow = true;
          }else{
            await this.$server.update("orders", {_id: this.order._id,teleConsultant:null,onlyCommentedit:true});  
            alert(data,"warning");
            this.motifUnrached="";
            this.order = {
              customer: {},
              details: [],
              seller: {},
              teleConsultant: {},
              skippedBy: [],
              unreachedBy: [],
            };
            this.showButtonStart = true;
            this.vShow = true;
            }
      
    },
    /****************** Edit Information **********************/
    activateInEditMode() {
      this.isEditing = true;
      this.edit = false;
      this.style = true;
    },

      refreshData(message,type){
       alert(message,type);
       this.motifUnrached="";
       this.motifAnnulation="";
        this.remindDate=null;
        this.showButtonStart = true;
         this.vShow = true;
          this.order = {
            customer: {},
            details: [],
            seller: {},
            teleConsultant: {},
            skippedBy: [],
            unreachedBy: [],
          };
          this.shipping={}
    },

    async updateData(dataToEdit) {
      this.$confirm(this.$t('update_information')).then(
        async (res) => {
          if (res) {
            const data = await this.$server.update("orders", dataToEdit);
            if (data._id) {
              this.isEditing = false;
              this.style = false;
              this.edit = true;
              alert(this.$t('updated'), "success");
            }
          }
        }
      );
    },
    async desactivateInResetMode() {
      this.isEditing = false;
      this.style = false;
      this.edit = true;
      await this.getOrder();
    },

    ChronoStart() {
      if (this.sec < 60) {
        this.s = setTimeout(() => {
          this.sec += 1;
          this.ChronoStart();
        }, 1000);
        this.remettreAZero();
      } else if (this.min == 60) {
        this.hr += 1;
        this.min = 0;
        this.h = setTimeout(() => {
          this.sec += 1;
          this.ChronoStart();
        }, 1000);
        this.remettreAZero();
      } else {
        this.min += 1;
        this.sec = 0;
        this.m = setTimeout(() => {
          this.sec += 1;
          this.ChronoStart();
        }, 1000);
        this.remettreAZero();
      }
    },

    remettreAZero() {
      if (this.sec < 10) {
        this.zeroS = true;
      } else {
        this.zeroS = false;
      }
      if (this.min < 10) {
        this.zeroM = true;
      } else {
        this.zeroM = false;
      }
      if (this.hr < 10) {
        this.zeroH = true;
      } else {
        this.zeroH = false;
      }
    },
  },
};
</script>


<style scoped>
.intro-y.active {
  border: 5px solid rgba(16, 179, 29, 0.9) !important;
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}

.btn-marketing {
    -webkit-animation: shadow-pulse 1s infinite;
    animation: shadow-pulse 1s infinite;
}
@keyframes shadow-pulse{ 
    0%{box-shadow:0 0 0 0px rgba(30, 112, 185);}
    100%{box-shadow:0 0 0 15px rgba(0, 0, 0, 0);}
}
#alert-border-1{
  background: #d4e7ff;
}
.vm2_wrapper{
  z-index: 11;
}
.phone{
  background-color: #e8bc52;
  border-color: #e8bc52;
  color: #fff !important;
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  padding: 2px;
}
.box-3{
  /* background-image: linear-gradient( 117deg,  rgba(123,216,96,1) 39.2%, rgba(255,255,255,1) 156.2% ); */
  /* background-image: linear-gradient( 180.5deg,  rgba(46,255,171,1) 12.3%, rgba(252,251,222,0.46) 92% ); */
  background-image: linear-gradient( 109.6deg,  rgba(223,234,247,1) 11.2%, rgba(244,248,252,1) 91.1% );
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.badge-soft-success {
  color: #e8bc52;
background-color: rgba(232,188,82,.1);
display: inline-block;
padding: 2px 2px;
font-size: 14px;
font-weight: 500;
line-height: 1;
text-align: center;
white-space: nowrap;
vertical-align: baseline;
border-radius: 10px;
}
.card-1{
  margin-bottom: 1.1rem;
  -webkit-box-shadow: 0 1px 2px rgba(56,65,74,.15);
  box-shadow: 0 1px 2px rgba(56,65,74,.15);
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-image: linear-gradient( 181.2deg,  rgba(181,239,249,1) 10.5%, rgba(254,254,254,1) 86.8% );
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 0.25rem;
}
.card-header-1:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.card-header-1 {
  border-bottom: 1px solid #e9ebec;
}
.card-header-1 {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
}
.card-title {
  font-size: 17px;
}
h5 {
  font-weight: 600;
  line-height: 1.2;
}
.text-muted {
  --vz-text-opacity: 1;
  color: #878a99 !important;
}
@-moz-keyframes facebookSpinner {
  0% {
    -moz-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: 0.1;
  }
}
@-webkit-keyframes facebookSpinner {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: 0.1;
  }
}
@keyframes facebookSpinner {
  0% {
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    -moz-transform: scale(0.7, 0.7);
    -ms-transform: scale(0.7, 0.7);
    -webkit-transform: scale(0.7, 0.7);
    transform: scale(0.7, 0.7);
    opacity: 0.1;
  }
}
.facebook-spinner {
  width: 25px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
}
.facebook-spinner .spinner-block {
  float: left;
  width: 7px;
  height: 28px;
  background-color: #8b9dc3;
  border: 1px solid #3b5998;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-animation-name: facebookSpinner;
  -webkit-animation-name: facebookSpinner;
  animation-name: facebookSpinner;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.facebook-spinner .spinner-block:not(:last-child) {
  margin-right: 2px;
}
.facebook-spinner .block-1 {
  -moz-animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}
.facebook-spinner .block-2 {
  -moz-animation-delay: 200ms;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.facebook-spinner .block-3 {
  -moz-animation-delay: 300ms;
  -webkit-animation-delay: 300ms;
  animation-delay: 300ms;
}


.me-1 {
  margin-right: .25rem !important;
}
.align-middle {
  vertical-align: middle !important;
}
.card-body-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
.maxx-heigh{
  max-height: 50px;
   overflow-y: scroll;
}
.icon::after {
  content: "";
  display: block;
  position: absolute;
  border-top: 23px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 12px solid #ff5b2d;
  left: 100%;
  top: 0;
}
.v-select{
  width: 100%;
}
.vs__dropdown-toggle {
    border: none;
}
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.label {
  transition: all 0.2s ease-out;
  top: 0.4rem;
  left: 0;
}
.flip-clock {
  text-align: center;
  perspective: 600px;
  margin: 0 auto;
}

.flip-clock__piece {
  display: inline-block;
  margin: 0 0.2vw;
  
  @media (min-width: 1000px) {
    margin: 0 5px;
  }
}

.flip-clock__slot {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.flip-card {
  display: block;
  position: relative;
  padding-bottom: 0.1em;
  font-size: 2.25rem;
  line-height: 0.95;
}

@media (min-width: 1000px) {
  .flip-clock__slot { font-size: 1.2rem; }
  .flip-card { font-size: 3rem; }
}


/*////////////////////////////////////////*/


.flip-card__top,
.flip-card__bottom,
.flip-card__back-bottom,
.flip-card__back::before,
.flip-card__back::after {
  display: block;
  height: 1.44em;
  color: #ccc;
  background: #222;
  padding: 0.23em 0.25em 0.4em;
  border-radius: 0.15em 0.15em 0.15em 0.15em;
  backface-visiblity: hidden;
  transform-style: preserve-3d;
  width: 1.8em;
}

.flip-card__bottom,
.flip-card__back-bottom {
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 1px #000;
  background: #393939;
  border-radius: 0 0 0.15em 0.15em;
  pointer-events: none;
  overflow: hidden;
  z-index: 2;
}

.flip-card__back-bottom {
  z-index: 1;
}

.flip-card__bottom::after,
.flip-card__back-bottom::after {
  display: block;
  margin-top: -0.72em;
}

.flip-card__back::before,
.flip-card__bottom::after,
.flip-card__back-bottom::after {
  content: attr(data-value);
}

.flip-card__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0%;
  pointer-events: none;
}

.flip-card__back::before {
  position: relative;
  overflow: hidden;
  z-index: -1;
}

.flip .flip-card__back::before {
  z-index: 1;
  animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .flip-card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);
}
.icon-call{
  font-size: 40px;
}
@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%, 99% {
    opacity: 1;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%, 50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
    z-index: 5;
  }
}

.gap-2 {
  gap: .5rem !important;
}
.vstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.card-radio {
  padding: 0;
}
.form-check {
  position: relative;
  text-align: left;
}
.form-check {
  display: block;
  min-height: 1.2375rem;
  margin-bottom: .125rem;
}
.card-radio .form-check-label {
  background-color: #fff;
  border: 1px solid #e7eaec;
  border-radius: .25rem;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  padding-right: 32px;
  cursor: pointer;
  margin-bottom: 0;
  font-weight: 400;
  background-image: linear-gradient(to right, #00b4db80, #0083b005);
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.avatar-xs {
  height: 2rem;
  width: 2rem;
}
.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #6691e7;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.fs-18 {
  font-size: 18px !important;
}
.rounded {
  border-radius: 0.25 !important;
}
.bg-success-subtle {
  background-color: #daf7e8 !important;
}
.text-success {
  --vz-text-opacity: 1;
  color: rgba(19,197,107,1) !important;
}
.card-radio .form-check-label {
  white-space: nowrap;
}
.form-check label {
  cursor: pointer;
}
</style>



